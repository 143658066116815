(function() {
  'use strict';

  function ImportTasksController(
      $log,
      $q,
      $rootScope,
      $state,
      ApiIDList,
      AsyncTasksSearch,
      CeleryTasks,
      Profile,
      List,
      Security,
      Utils,
      IMPORT_TASKS_STATES) {
    var ctrl = this;

    Utils.setPageTitle('Import tasks');

    ctrl.options = {
      trackBy: '_id',
      borderClass: function(item) {
        var borderStyle = 'info',
            taskState = IMPORT_TASKS_STATES[item.state];
        if (!_.isUndefined(taskState)) {
          borderStyle = taskState.borderStyle;
        }
        return 'progress-border-' + borderStyle;
      }
    };

    ctrl.list = new List({ idField: '_id' });

    var listName = 'importer';
    ctrl.searchModel = Profile.getListPreferences(listName).current;
    var searchCode = 'search';

    var hasSuperUserRole = Security.hasRole('system:superadmin', { promise: true })
        .then(function() { return true; }).catch(function() { return false; });

    $q.all([hasSuperUserRole, AsyncTasksSearch.getSearch(searchCode)])
      .then(function(result) {
        var search = result[1];
        var listOptions = {
          search: search,
          model: ctrl.searchModel,
          idField: 'id',
          defaultFilter: { tool: 'csv_kaizen' },
          searchFn: CeleryTasks.searchIds.bind(CeleryTasks),
          fetchFn: CeleryTasks.fetchIds.bind(CeleryTasks)
        };

        search.ready = true;
        return new ApiIDList(listOptions);
      })
      .then(function(list) {
        ctrl.list = list;
        $rootScope.$broadcast('KZCurrentListChange', { list: listName, search: list.search });
        $rootScope.loading = false;
        ctrl.list.doLoadItems();
        ctrl.loaded = true;
      })
      .catch(function(error) {
        $rootScope.loading = false;
        ctrl.loaded = true;
        $log.warn(error);
      });

    ctrl.actions = [
      {
        label: 'Import new data',
        icon: 'icon-plus',
        href: (function() { return $state.href('epf.importer.new'); })(),
        klass: 'btn-success'
      }
    ];
  }

  ImportTasksController.$inject = [
    '$log',
    '$q',
    '$rootScope',
    '$state',
    'APIIDListFactory',
    'AsyncTasksSearch',
    'CeleryTasksService',
    'ProfileService',
    'ListFactory',
    'SecurityService',
    'UtilsService',
    'IMPORT_TASKS_STATES'
  ];

  angular.module('component.importer')
    .controller('ImportTasksController', ImportTasksController);
})();
