(function() {
  'use strict';

  var taskNames = {
    import_file: 'Bulk import users or events via CSV',
    bulk_merge_users: 'Merge users in Bulk',
    bulk_merge_msfs: 'Merge MSFs events in Bulk'
    // bulk_upgrade_goalsets: 'Upgrade goalsets to the latest published version'
  };

  var importTasksStates = {
    new: {
      id: 'new',
      name: 'New',
      labelStyle: 'info',
      borderStyle: 'info'
    },
    processing: {
      id: 'processing',
      name: 'Processing',
      labelStyle: 'warning',
      borderStyle: 'pending'
    },
    success: {
      id: 'success',
      name: 'Completed',
      labelStyle: 'success',
      borderStyle: 'complete'
    },
    failure: {
      id: 'failure',
      name: 'Failed',
      labelStyle: 'danger',
      borderStyle: 'danger'
    },
    aborted: {
      id: 'aborted',
      name: 'Aborted',
      labelStyle: 'danger',
      borderStyle: 'danger'
    }
  };

  var bulkActionsParams = {
    bulk_merge_users: {
      helpTextHtml: '' +
        'To merge users, you\'ll need to fill out a CSV file with two columns: ' +
        '<em>"src_profile_id"</em> and <em>"dest_profile_id"</em>.<br/>' +
        'The <em>"src_profile_id"</em> column should contain the ID of the user whose data you ' +
        'want to merge, and the <em>"dest_profile_id"</em> column should contain the ID of the ' +
        'user whose data you want to merge onto.<br/><br/>' +
        'You can find the user ID by navigating to the user\'s summary page and ' +
        'looking at the URL. It should be in this format: <code>/#/summary/{USER ID}</code>.' +
        '<br/><br/>\nEach row in the CSV file represents a single merge operation.\n<br><br>',
      CSVTemplate: {
        headers: 'src_user_id,dest_user_id',
        name: 'merge_users.csv'
      },
      formElements: [{ type: 'csv', label: 'Select the CSV to merge users.' }],
      successNotification: 'Task to merge users added, please wait...',
      actionButtonTitle: 'Merge users'
    },
    bulk_merge_msfs: {
      helpTextHtml: 'To merge MSF events, you\'ll need to fill out a CSV file with two' +
        ' columns: <em>"src_event_id"</em> and <em>"dest_event_id"</em>.<br/>' +
        'The <em>"src_event_id"</em> column should contain the ID of the event(s) you want to ' +
        'merge, and the <em>"dest_event_id"</em> column should contain the ID of the event you ' +
        'want to merge the source event(s) onto.<br/><br/>' +
        'You can find the event ID by navigating to the event\'s view page and looking at the' +
        ' URL. It should be in this format: <code>/#/events/view/{EVENT ID}</code>.<br/><br/>' +
        'Each row in the CSV file represents a source event to be merged. Before performing ' +
        'the merge operation, the system will group all rows with the same ' +
        '<em>"dest_event_id"</em> and merge the corresponding source events as a single ' +
        'operation. When merging, the first section of the destination event will be retained,' +
        ' while the first section of each source event will be removed. Finally, the MSF' +
        ' responses will be added to the destination event in the order they are listed in the ' +
        'CSV file.<br><br>',
      CSVTemplate: {
        headers: 'src_event_id,dest_event_id',
        name: 'merge_msfs_events.csv'
      },
      formElements: [{ type: 'csv', label: 'Select the CSV to merge MSFs events.' }],
      successNotification: 'Task to merge msfs added, please wait...',
      actionButtonTitle: 'Merge MSFs'
    },
    bulk_upgrade_goalsets: {
      helpTextHtml: '',
      CSVTemplate: {
        headers: 'email',
        name: 'filter_by_user_email.csv'
      },
      formElements: [
        {
          type: 'formly-form',
          fields: [
            { id: 'eventTypeId', type: 'string', label: 'Event type id', required: true },
            { id: 'relink', type: 'boolean', label: 'Relink Existing events', required: false },
            {
              id: 'updateAllVersionsExceptPublished',
              type: 'boolean',
              label: 'Update all versions from same group except published',
              required: false
            },
            {
              id: 'updateClosedGoalsets',
              type: 'boolean',
              label: 'Update closed goalsets',
              required: false
            }
          ]
        },
        { type: 'csv', label: 'Select the CSV to filter by users\' email.' }
      ],
      successNotification: 'Task to upgrade goalsets, please wait...',
      actionButtonTitle: 'Upgrade goalsets'
    }
  };

  angular.module('component.importer')
    .constant('TASK_NAMES', taskNames)
    .constant('IMPORT_TASKS_STATES', importTasksStates)
    .constant('BULK_ACTIONS_PARAMS', bulkActionsParams);
})();
